import { Box, Button, Drawer } from "@mui/material";
import React from "react";

const DrawerComponent = ({ open, handleDrawer, children, ...props }) => {
  return (
    <Drawer open={open} onClose={() => handleDrawer(false)} {...props}>
      <Box sx={{ minWidth: 500 }}>{children}</Box>
    </Drawer>
  );
};

export default DrawerComponent;
