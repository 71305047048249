import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import React from "react";

const RadioButtonComponent = ({
  value,
  name,
  radiolabel,
  radioListStyle,
  menuItems = [],
  ...props
}) => {
  return (
    <FormControl>
      <FormLabel id="demo-radio-buttons-group-label">{radiolabel}</FormLabel>
      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        defaultValue=""
        name={name}
        value={value}
        {...props}
      >
        {menuItems?.map((item) => (
          <FormControlLabel
            key={item?.name}
            value={item?.value}
            control={<Radio />}
            label={item?.name}
            sx={radioListStyle}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export default RadioButtonComponent;
