import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

const steps = [
  "Personal Details",
  "Bank Details",
  "KYC Verification",
  "Work Detail",
  "Re-Payment Setup",
  "E-Sign",
];

export default function StepperComponent({ ...props }) {
  return (
    <Box sx={{ width: "100%" }}>
      <Stepper activeStep={3} alternativeLabel {...props}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}
