import { FormControl, FormHelperText, TextField } from "@mui/material";

import React from "react";

const TextFieldComponent = ({
  error,
  errorMessage,
  toUpperTrue,
  maxLength = 25,
  ...props
}) => {
  return (
    <FormControl fullWidth>
      <TextField
        variant="outlined"
        error={Boolean(error)}
        inputProps={{ maxLength }}
        sx={{ input: { textTransform: toUpperTrue ? 'uppercase' : '' } }}
        {...props}
      />
      {error && (
        <FormHelperText sx={{ color: "#d32f2f", m: 0 }}>
          {errorMessage}
        </FormHelperText>
      )}
    </FormControl>
  );
};
export default TextFieldComponent;
