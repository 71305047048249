"use client";
import { useEffect } from "react";
import LoginScreen from "@/screens/login/Login";

export default function Home() {
  useEffect(() => {
    sessionStorage.setItem("token", "");
    sessionStorage.setItem("appId", "");
    sessionStorage.setItem("username", "");
    sessionStorage.clear();
  }, []);

  return <LoginScreen />;
}
