import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  Box,
  Container,
  Dialog,
  DialogContent,
  DialogContentText,
  IconButton,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SubmitButtonComponent from "@/components/buttons/SubmitButtonComponent";
import { TextFieldComponent } from "@/components";
import { NUMBER_REGEX } from "@/lib/Constants";

const initialValue = {
  appId: "",
};
const validationSchema = Yup.object().shape({
  appId: Yup.string().required("Application Id is Required"),
});

export default function NumberIdModal({
  open,
  handleModalClose,
  handleVerifyAppId,
  appId,
  setAppId,
  urlId,
  error,
}) {
  //HOOKS
  const [otp, setOtp] = useState("");
  const [appIdNew, setAppIdNew] = useState("");

  useEffect(() => {
    if (open) {
      if (urlId !== "" || urlId !== null) {
        setAppIdNew(urlId);
        formik.initialValues.appId = urlId;
      } else {
        setAppIdNew("");
      }
    }
  }, [open]);

  useEffect(() => {
    if (error !== null && error !== undefined) {
      if (error?.status === 404) {
        setAppIdNew("");
      }
    }
  }, [error]);

  const formik = useFormik({
    initialValues: initialValue,
    validationSchema,
    onSubmit: (values) => {
      //   generateOtp({ phone: values?.phone });
    },
  });

  //FUNCTIONS
  const handleClose = () => {
    formik.resetForm();
    setAppIdNew("");
    handleModalClose(false);
  };

  const handleFieldChange = (e) => {
    const isValid = NUMBER_REGEX(e.target.value);
    if (isValid) {
      setAppIdNew(e.target.value);
      formik.handleChange(e);
    }
  };

  const handleVerify = () => {
    formik.resetForm();
    setAppId(appIdNew);
    handleVerifyAppId(appIdNew);
  };

  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        sx={{ textAlign: "center" }}
        open={open}
        onClose={handleClose}
      >
        <Box sx={{ display: "flex", justifyContent: "flex-end", px: 1 }}>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <DialogContent>
          <DialogContentText>
            <Typography
              component={"span"}
              sx={{ display: "block", textAlign: "center" }}
            >
              <strong>Enter your loan application id</strong>
            </Typography>

            <Typography
              component={"span"}
              sx={{ display: "block", textAlign: "center" }}
            >
              mentioned in the loan application
            </Typography>
          </DialogContentText>
          <TextFieldComponent
            variant="standard"
            name="appId"
            style={{ marginTop: "40px" }}
            label="Application Id"
            value={appIdNew}
            placeholder="Enter your Application Id"
            errorMessage={error?.data?.message}
            error={error !== null && error !== undefined}
            onChange={(e) => {
              handleFieldChange(e);
            }}
            maxLength={10}
          />

          <Container sx={{ width: "100%", mb: 5, marginTop: "30px" }}>
            <SubmitButtonComponent
              buttonStyle={{
                width: "25%",
                p: 1,
                borderRadius: 5,
                backgroundColor: "#009640",
              }}
              disabled={!(formik.isValid && formik.dirty) && urlId === ""}
              onClick={handleVerify}
            >
              Verify
            </SubmitButtonComponent>
          </Container>
        </DialogContent>
      </Dialog>
    </div>
  );
}
