import React from "react";
import { IconButton, Stack, Typography } from "@mui/material";
import Image from "next/image";

const SecureDataComponent = ({ ...props }) => {
  return (
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={1}
    >
      <Image src={"/shield.svg"} alt="Secure shield" height={14} width={16} />

      <Typography variant="caption" align="center" {...props}>
        Your Data is 100% safe and Secure with us
      </Typography>
    </Stack>
  );
};

export default SecureDataComponent;
