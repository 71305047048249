import React from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  Box,
  Container,
  Dialog,
  DialogContent,
  DialogContentText,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SubmitButtonComponent from "@/components/buttons/SubmitButtonComponent";
import { TextFieldComponent } from "@/components";
import { MOBILE_REGEX } from "@/lib/Constants";

const initialValue = {
  phoneNumber: "",
};
const validationSchema = Yup.object().shape({
  phoneNumber: Yup.string()
    .required("Mobile Number is Required")
    .matches(/^[6-9]\d{9}$/, "Please enter a valid mobile number")
    .length(10, "Mobile Number should be 10 Digits"),
});

export default function PhoneNumberModal({
  open,
  handleModalClose,
  handleVerifyPhoneNumber,
}) {
  const formik = useFormik({
    initialValues: initialValue,
    validationSchema,
    onSubmit: (values) => {
      //   generateOtp({ phone: values?.phone });
    },
  });

  //FUNCTIONS
  const handleClose = () => {
    formik.resetForm();
    handleModalClose(false);
  };

  const handleFieldChange = (e) => {
    const isValid = MOBILE_REGEX(e.target.value);
    if (isValid) {
      formik.handleChange(e);
    }
  };

  const handleVerify = () => {
    formik.resetForm();
    handleVerifyPhoneNumber(formik.values.phoneNumber);
  };

  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        sx={{ textAlign: "center" }}
        open={open}
        onClose={handleClose}
      >
        <Box sx={{ display: "flex", justifyContent: "flex-end", px: 1 }}>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <DialogContent>
          <DialogContentText>
            <Typography
              variant="span"
              sx={{ display: "block", textAlign: "center" }}
            >
              <strong>Enter your Mobile Number</strong>
            </Typography>

            <Typography
              variant="span"
              sx={{ display: "block", textAlign: "center" }}
            >
              mentioned in the loan application
            </Typography>
          </DialogContentText>
          <TextFieldComponent
            variant="standard"
            name="phoneNumber"
            style={{ marginTop: "40px", marginBottom: "30px" }}
            maxLength={10}
            label="Mobile Number"
            value={formik.values.phoneNumber}
            placeholder="Enter your Mobile Number"
            onChange={(e) => {
              formik.setFieldTouched("phoneNumber", true);
              handleFieldChange(e);
            }}
            error={Boolean(
              formik.touched.phoneNumber && formik.errors.phoneNumber
            )}
            helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
            onBlur={formik.handleBlur}
          />

          {/* <Container sx={{ width: "100%", mb: 5 }}> */}
          <Grid Container style={{ display: "flex", justifyContent: "center" }}>
            <Grid item xs={12} md={8}>
              <SubmitButtonComponent
                buttonStyle={{
                  width: "80%",
                  p: 1,
                  borderRadius: 5,
                  backgroundColor: "#009640",
                }}
                disabled={!(formik.isValid && formik.dirty)}
                onClick={handleVerify}
              >
                Send OTP
              </SubmitButtonComponent>
            </Grid>
          </Grid>
          {/* </Container> */}
        </DialogContent>
      </Dialog>
    </div>
  );
}
