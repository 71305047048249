import React from "react";
import { Box, Container, Dialog, DialogContent, DialogContentText, DialogTitle, IconButton, Stack, Typography, } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SubmitButtonComponent from "@/components/buttons/SubmitButtonComponent";

export default function NumberFoundModal({ open, handleModalClose, hitSendOtp, phoneNumber }) {

  const handleClose = () => {
    handleModalClose(false);
  };

  return (
    <div>
      <Dialog
        fullWidth={true}
        maxWidth="sm"
        sx={{ textAlign: "center" }}
        open={open}
        onClose={handleClose}
      >
        <Box sx={{ display: "flex", justifyContent: "flex-end", px: 1 }}>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <DialogTitle>
          <strong>Number Found</strong>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography
              variant="span"
              sx={{ display: "block", textAlign: "center" }}
            >
              {`Existing mobile number is ${phoneNumber}. If this is not correct please contact customer care`}
            </Typography>
          </DialogContentText>

          <Container sx={{ width: "100%", mb: 5 }} spacing={2}>
            <Stack mt={2} p={2} spacing={2} justifyContent="space-between">
              <SubmitButtonComponent
                variant={"outlined"}
                type={"submit"}
                buttonStyle={{
                  color: "green",
                  borderRadius: 2.5,
                  borderColor: "green",
                  borderWidth: "2",
                  width: "100%",
                  backgroundColor: "transparent",
                }}
                onClick={() => {
                  hitSendOtp(true);
                }}
              >
                Send OTP
              </SubmitButtonComponent>
              <SubmitButtonComponent
                variant={"outlined"}
                type={"submit"}
                buttonStyle={{
                  color: "green",
                  borderRadius: 2.5,
                  borderColor: "green",
                  borderWidth: "2",
                  width: "100%",
                  backgroundColor: "transparent",
                }}
                onClick={() => {
                  handleModalClose(false);
                }}
              >
                Go Back
              </SubmitButtonComponent>
            </Stack>
          </Container>
        </DialogContent>
      </Dialog>
    </div>
  );
}
