"use client";
import React from "react";
import { enqueueSnackbar } from "notistack";

const SnackBarSuccessComponent = (success = "") => {
  return enqueueSnackbar(success, {
    variant: "success",
    autoHideDuration: 2000,
    preventDuplicate: true,
  });
};

export default SnackBarSuccessComponent;
