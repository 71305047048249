"use client";
import { Button, useTheme } from "@mui/material";
import React from "react";

const SubmitButtonComponent = ({
  variant = "contained",
  type = "submit",
  children,
  buttonStyle,
  ...props
}) => {
  const theme = useTheme();
  return (
    <Button
      variant={variant}
      type={type}
      sx={{
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.pale.main,
        textTransform: "none",

        "&:hover": {
          color: theme.palette.primary.main,
          backgroundColor: theme.palette.pale.main,
          cursor: "pointer",
        },
        ...buttonStyle,
      }}
      {...props}
    >
      {children}
    </Button>
  );
};

export default SubmitButtonComponent;
