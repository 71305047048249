"use client";
import React, { useRef, useState } from "react";
import Head from "next/head";
import {
  GoogleMap,
  LoadScript,
  Autocomplete,
  Marker,
} from "@react-google-maps/api";

const MapsComponent = () => {
  const [selectedPlace, setSelectedPlace] = useState(null);
  const libraries = ["places"];
  const mapContainerStyle = {
    position: "relative",
    height: "800px",
    width: "100%",
  };

  const defaultCenter = {
    lat: 41.3851,
    lng: 2.1734,
  };

  const onPlaceChanged = (place) => {
    if (place) {
      if (place.geometry && place.geometry.location) {
        setSelectedPlace({
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        });
      } else {
      }
    } else {
    }
  };

  return (
    <LoadScript
      googleMapsApiKey={`${process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY}`}
      libraries={libraries}
    >
      <div style={{ position: "relative" }}>
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          zoom={13}
          center={defaultCenter}
        >
          {selectedPlace && <Marker position={selectedPlace} />}
          <Autocomplete
            onLoad={(autocomplete) =>
              console.log("Autocomplete loaded:", autocomplete)
            }
            onPlaceChanged={onPlaceChanged}
            options={{
              types: ["geocode"], // Restrict to geocoding results only
              fields: ["formatted_address", "geometry"], // Request additional place data
            }}
          >
            <input
              type="text"
              placeholder="Enter a location"
              style={{
                boxSizing: `border-box`,
                border: `1px solid transparent`,
                width: `240px`,
                height: `32px`,
                padding: `0 12px`,
                borderRadius: `3px`,
                boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                fontSize: `14px`,
                outline: `none`,
                textOverflow: `ellipses`,
                position: "absolute",
                top: "20px",
                left: "50%",
                transform: "translateX(-50%)",
                zIndex: "1",
              }}
            />
          </Autocomplete>
        </GoogleMap>
      </div>
    </LoadScript>
  );
};

export default MapsComponent;
