import { GIFS, webColors } from "@/lib/Constants";
import { Container, Stack, Typography } from "@mui/material";
import Image from "next/image";
import React from "react";

const PollingComponent = ({ text }) => {
  return (
    <Container sx={{ mt: 3 }}>
      <Stack justifyContent="center" alignItems="center" my={8} spacing={3}>
        <Image src={GIFS.polling} alt="Polling" />
        <Typography color={webColors.primary} fontSize={24} fontWeight={700}>
          {text ? `${text}` : "Processing"}
        </Typography>
        <Typography color={webColors.fadeColor} fontWeight={700}>
          Do not press back or refresh button. It may take upto 2-3 minutes.
          Please Wait...
        </Typography>
      </Stack>
    </Container>
  );
};

export default PollingComponent;
