import React, { useCallback, useEffect, useState } from "react";
import { MuiOtpInput } from "mui-one-time-password-input";
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SubmitButtonComponent from "@/components/buttons/SubmitButtonComponent";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

export default function OtpModal({
  open,
  handleModalClose,
  phone,
  verifyOtp,
  hitResendOtp,
  resendOtp,
  setResendOtp,
  componentType,
}) {
  //HOOKS
  const [otp, setOtp] = useState("");
  const otpTime = 60;

  const [timer, setTimer] = useState(60);
  const timeOutCallback = useCallback(
    () => setTimer((currTimer) => currTimer - 1),
    []
  );
  useEffect(() => {
    if (open) {
      setTimer(otpTime);
    }
  }, [open]);

  useEffect(() => {
    if (resendOtp) {
      setResendOtp(false);
      resetTimer();
    }
  }, [resendOtp]);

  useEffect(() => {
    timer > 0 && setTimeout(timeOutCallback, 1000);
  }, [timer, timeOutCallback]);

  const resetTimer = function () {
    if (!timer || timer === -1) {
      setTimer(otpTime);
    }
  };

  //FUNCTIONS
  const handleClose = () => {
    setOtp("");
    handleModalClose(false);
  };

  const handleChange = (value) => {
    setOtp(value);
  };
  const hanldeVerifyOtp = () => {
    verifyOtp(false, otp);
  };

  const onResendPressed = () => {
    hitResendOtp();
    setOtp("");
  };

  const showTimeLeft = (timer) => {
    var m = Math.floor(timer / 60);
    var s = timer % 60;

    m = m < 10 ? "0" + m : m;
    s = s < 10 ? "0" + s : s;

    return m + ":" + s;
  };
  return (
    <div>
      <Dialog sx={{ textAlign: "center" }} open={open} onClose={handleClose}>
        <Box sx={{ display: "flex", justifyContent: "flex-end", px: 1 }}>
          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <DialogTitle>
          <strong>Verify OTP</strong>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {componentType !== "esign" ? (
              <>
                <Typography
                  component={"span"}
                  sx={{ display: "block", textAlign: "center" }}
                >
                  <strong>
                    Kindly enter the 6 Digit verification code sent to
                  </strong>
                </Typography>
                {phone ? (
                  <Typography
                    component={"span"}
                    sx={{
                      display: "block",
                      textAlign: "center",
                      color: "#093",
                    }}
                  >
                    +91 {phone}
                  </Typography>
                ) : (
                  <Typography component={"span"} fontWeight={700}>
                    your registered mobile number
                  </Typography>
                )}
              </>
            ) : (
              <>
                <Typography
                  component={"span"}
                  sx={{ display: "block", textAlign: "center" }}
                >
                  <strong>
                    {`Please enter OTP received on your registered mobile number ${
                      phone ? phone : ""
                    }`}
                  </strong>
                </Typography>
              </>
            )}
          </DialogContentText>
          <MuiOtpInput
            sx={{ my: 2 }}
            value={otp}
            length={6}
            onChange={handleChange}
            validateChar={(val) => !isNaN(val)}
            TextFieldsProps={{
              variant: "standard",
              // type: "number",
              inputProps: {
                inputMode: "numeric",
                pattern: "[0-9]*",
              },
            }}
          />
          <DialogContentText
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              p: 2,
            }}
          >
            <Typography component={"span"}>
              {"Didn't recieve the OTP?"}{" "}
            </Typography>
            {timer > 0 ? (
              <>
                <AccessTimeIcon style={{ margin: "10px" }} />
                {showTimeLeft(timer)}
              </>
            ) : (
              <Button sx={{ ml: 1 }} onClick={onResendPressed}>
                Resend OTP
              </Button>
            )}
          </DialogContentText>
          <Container sx={{ width: "70%", mb: 5 }}>
            <SubmitButtonComponent
              disabled={otp?.length !== 6}
              onClick={hanldeVerifyOtp}
              buttonStyle={{
                width: "50%",
                p: 1,
                borderRadius: 5,
                backgroundColor: "#009640",
              }}
            >
              Verify
            </SubmitButtonComponent>
          </Container>
        </DialogContent>
      </Dialog>
    </div>
  );
}
