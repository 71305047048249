"use client";

import { enqueueSnackbar } from "notistack";

const SnackbarErrorComponent = (error) => {
  return enqueueSnackbar(error, {
    variant: "error",
    autoHideDuration: 4000,
    preventDuplicate: true,
  });
};

export default SnackbarErrorComponent;
